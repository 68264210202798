.app {
    text-align: center;
}

.main-logo {
    height: 10vmin;
    pointer-events: none;
}

.main {
    background-color: #282c34;
    min-height: 100vh;
    padding-top: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.form * {
    vertical-align: middle;
}

input {
    border: none;
    outline: inherit;
    box-sizing: border-box;
}

.url {
    padding: 10px 15px;
    font-size: 14px;
    width: 350px;
}

.download-button {
    margin-left: 10px;
    display: inline-block;
    height: 38px;
    cursor: pointer;
    border: 0;
    padding: 0;
}

.iframe {
    margin-top: 10px;
}

.error {
    padding-top: 10px;
    color: red;
}
